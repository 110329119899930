import { createAuthHandler } from '@synqly/middleware/auth/api'

export { handler as default, authConfig }

const authConfig = {
  slugParam: 'management-auth',
  canReset: process.env.ENABLE_RESET_PASSWORD !== 'false',
  canSignIn: process.env.ENABLE_SIGN_IN !== 'false',
  canSignUp: process.env.ENABLE_SIGN_UP !== 'false',
  redirectUrl: '/prod',
}

const handler = createAuthHandler(authConfig)
